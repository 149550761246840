<template>
  <div class="address-page">
    <headerBar :title="'银行卡管理'"  :showArrow="true" @back="back"></headerBar>
    
    <div class="flex-center-end remove-btn">
      <span>删除</span>
    </div>

    <div class="bank-main">
      <div class="bank-info">
        <div class="flex-center-between">
          <div class="name">中国银行</div>
          <div>
            <img :src="bankUrl5" alt="">
          </div>
        </div>
        <div class="user-name">张三</div>
        <div class="card-num">{{!showCard ? '1238102938120938123' : '************8123'}}</div>
      </div>

      <div class="bank-btn flex-center" v-show="pageType == 1">
        <img :src="showCard ? eyeUrl2 : eyeUrl" alt="" @click="showCard = !showCard">
        <img :src="editUrl" alt="" @click="showEdit">
        <img :src="copyUrl" alt="" class="copy-icon" @click="copyData">
      </div>

      <div class="tabs flex-center" v-show="pageType == 1">
        <div class="tab" :class="tabIndex == 0 ? 'active' : ''" @click="changeTab(0)">全部</div>
        <div class="tab" :class="tabIndex == 1 ? 'active' : ''" @click="changeTab(1)">充值</div>
        <div class="tab" :class="tabIndex == 2 ? 'active' : ''" @click="changeTab(2)">提现</div>
      </div>

      <div class="log-list" v-show="pageType == 1">
        <div class="list" v-for="(item, i) in logList" :key="i">
          <div class="flex-center-between">
            <div class="money">+100</div>
            <div class="status" :class="item.type == 1 ? 'green' : 'yellow'">
              {{item.type == 1 ? '充值' : '提现'}}
            </div>
          </div>
          <div class="flex-center-between">
            <div></div>
            <div class="time">2024-04-15 12:33:44</div>
          </div>
        </div>
      </div>

      <div class="address-form edit" v-show="pageType == 2">
        <div class="form-item">
          <div class="label">{{i18n.t('bind.Company')}}</div>
          <div class="input ">
            <input type="text" :readonly="readonly" v-model="form.Company" :placeholder="i18n.t('bind.placeholder4')">
          </div>
        </div>
        <div class="form-item">
          <div class="label">{{i18n.t('bind.RealName')}}</div>
          <div class="input ">
            <input type="text" :readonly="readonly" v-model="form.RealName" :placeholder="i18n.t('bind.placeholder5')">
          </div>
        </div>
        <div class="form-item" >
          <div class="label">{{i18n.t('bind.Card')}}</div>
          <div class="input ">
            <input type="text" :readonly="readonly" v-model="form.Card" :placeholder="i18n.t('bind.placeholder6')">
          </div>
        </div>
        <div class="form-item" >
          <div class="label label-self">{{i18n.t('bind.Type')}}</div>
          <div class="input ">
            <input type="text" :readonly="readonly" v-model="form.Type" :placeholder="i18n.t('bind.placeholder9')">
          </div>
        </div>
        <div class="form-item" >
          <div class="label label-self" >{{i18n.t('bind.TaxID')}}</div>
          <div class="input ">
            <input type="text" :readonly="readonly" v-model="form.TaxID" :placeholder="i18n.t('bind.placeholder8')">
          </div>
        </div>
        <div class="form-item" v-show="readonly === false">
          <div class="label">{{i18n.t('bind.label2')}}</div>
          <div class="input ">
            <input type="text" :readonly="readonly" v-model="form.password" :placeholder="i18n.t('bind.placeholder2')">
          </div>
        </div>

        <div class="btn" >
          <Button class="gray" @click="cancel">{{i18n.t('address.btn2')}}</Button>
          <Button @click="submit">{{i18n.t('address.btn3')}}</Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Clipboard from 'clipboard';
import { Button } from 'vant'
import headerBar from '@/components/header'
import {addBank, addCard, addUsdt, doWithdraw, getBankList, getUsdtList} from "@/api/user";
export default {
  components: {
    headerBar,
    Button
  },
  data() {
    return {
      pageType: 1,
      bankUrl1: require('../../assets/imgs/bank1.png'),
      bankUrl2: require('../../assets/imgs/bank2.png'),
      bankUrl3: require('../../assets/imgs/bank3.png'),
      bankUrl4: require('../../assets/imgs/bank4.png'),
      bankUrl5: require('../../assets/imgs/bank5.png'),
      removeUrl: require('../../assets/imgs/icon-remove.png'),
      eyeUrl: require('../../assets/imgs/icon-eye3.png'),
      eyeUrl2: require('../../assets/imgs/icon-eye4.png'),
      editUrl: require('../../assets/imgs/icon-edit.png'),
      copyUrl: require('../../assets/imgs/icon-copy.png'),
      tabIndex: 0,
      form: {
        Company: '',
        RealName: '',
        TaxID: '',
        Card: '',
        Type: '',
        password: '',
      },
      readonly: false,
      showCard: false,
      userMoney: '0.00',
      logList: [{}, {}, {},{}, {}],
      bankInfo: {
        CardNumber: ''
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      
    },
    back() {
      this.$router.go(-1)
    },
    setMoney(money) {
      this.form.number =  money
    },
    submit() {
        let form = new FormData()
        form.append('Company', this.form.Company)
        form.append('RealName', this.form.RealName)
        form.append('Card', this.form.Card)
        form.append('TaxID', this.form.TaxID)
        form.append('Type', this.form.Type)
        form.append('password', this.form.password)
      addBank(form).then(res => {
            if (res.code == 0) {
              this.back()
                this.$toast(res.msg)
            } else {
                this.$toast(res.msg)
            }
        })
    },
    setAll() {
      this.form.money = this.userMoney
    },
    changeTab(i) {
      this.tabIndex = i
    },
    showEdit() {
      this.pageType = 2
    },
    cancel() {
      this.pageType = 1
    },
    copyData() {  
      let data = this.bankInfo.CardNumber || '123123123123'
      const clipboard = new Clipboard('.copy-icon', {text: () => data});
      this.$toast.success('复制成功')
    },
  }
}
</script>
<style scoped>
.label-self::before{
  content: none !important; /* 或 content: ""; */
}
</style>